<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-card-title>
        <span>Nuevo cliente</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="error">
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="name"
              outlined
              dense
              color="secondary"
              label="Nombre"
              :error-messages="errors.name"
              @input="validateName()"
              @blur="validateName()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastName"
              outlined
              dense
              color="secondary"
              label="Apellidos"
              :error-messages="errors.lastName"
              @input="validateLastName()"
              @blur="validateLastName()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="identification"
              outlined
              dense
              color="secondary"
              label="Cédula"
              :error-messages="errors.identification"
              @input="validateIdentification()"
              @blur="validateIdentification()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="email"
              outlined
              dense
              color="secondary"
              label="Correo electrónico (Opcional)"
              :error-messages="errors.email"
              @input="validateEmail()"
              @blur="validateEmail()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="phoneNumber"
              outlined
              dense
              color="secondary"
              label="Número telefónico (Opcional)"
              :error-messages="errors.phoneNumber"
              @input="validatePhoneNumber()"
              @blur="validatePhoneNumber()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="adress"
              outlined
              dense
              color="secondary"
              label="Dirección (Opcional)"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="discountPercent"
              outlined
              dense
              color="secondary"
              label="Porcentaje de descuento"
              append-icon="mdi-percent"
              :error-messages="errors.discountPercent"
              @input="validateDiscountPercent()"
              @blur="validateDiscountPercent()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              color="secondary"
              outlined
              class="mr-2"
              @click="$emit('cancel')"
            >
              <span>Cancelar</span>
            </v-btn>
            <v-btn
              color="secondary"
              :loading="loading"
              :disabled="!isFormValid"
              @click="submit"
            >
              <v-icon small class="mr-2">mdi-content-save</v-icon>
              <span>Guardar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, numeric, integer, email } from "vuelidate/lib/validators";
import serverRequestMixin from "@/mixins/serverRequest.mixin.js";

export default {
  mixins: [validationMixin, serverRequestMixin],

  props: {
    show: { type: Boolean, default: false },
    customer: { type: Object, default: () => null },
  },

  data: () => ({
    loading: false,
    error: "",
    name: "",
    lastName: "",
    identification: "",
    email: null,
    phoneNumber: null,
    adress: null,
    discountPercent: 0,
    errors: {
      name: [],
      lastName: [],
      identification: [],
      email: [],
      phoneNumber: [],
      discountPercent: [],
    },
  }),

  computed: {
    isFormValid() {
      if (this.errors.name.length) return false;
      if (this.errors.lastName.length) return false;
      if (this.errors.identification.length) return false;
      if (this.errors.email.length) return false;
      if (this.errors.phoneNumber.length) return false;
      if (this.errors.discountPercent.length) return false;
      return true;
    },
  },

  validations: {
    name: { required },
    lastName: { required },
    identification: { required },
    email: { email },
    phoneNumber: { numeric, integer },
    discountPercent: { required, numeric },
  },

  mounted() {
    this.setCurrentData();
  },

  methods: {
    setCurrentData() {
      if (this.customer) {
        this.name = this.customer.name;
        this.lastName = this.customer.lastName;
        this.identification = this.customer.identification;
        this.discountPercent = this.customer.discountPercent;
        if (this.customer.email) this.email = this.customer.email;
        if (this.customer.phoneNumber)
          this.phoneNumber = this.customer.phoneNumber;
        if (this.customer.adress) this.adress = this.customer.adress;
      }
    },

    validateForm() {
      this.validateName();
      this.validateLastName();
      this.validateIdentification();
      this.validateEmail();
      this.validatePhoneNumber();
      this.validateDiscountPercent();
    },

    validateName() {
      this.$v.name.$touch();
      this.errors.name = [
        ...(this.$v.name.required
          ? []
          : ["El nombre del cliente es requerido"]),
      ];
    },

    validateLastName() {
      this.$v.lastName.$touch();
      this.errors.lastName = [
        ...(this.$v.lastName.required
          ? []
          : ["El apellido del cliente es requerido"]),
      ];
    },

    validateIdentification() {
      this.$v.identification.$touch();
      this.errors.identification = [
        ...(this.$v.identification.required
          ? []
          : ["La cédula del cliente es requerida"]),
      ];
    },

    validateEmail() {
      this.$v.email.$touch();
      this.errors.email = [
        ...(!this.$v.email.email && this.email
          ? ["Ingresa una dirección de correo válida"]
          : []),
      ];
    },

    validatePhoneNumber() {
      this.$v.phoneNumber.$touch();
      this.errors.phoneNumber = [
        ...(!this.$v.phoneNumber.numeric && this.phoneNumber
          ? ["Ingresa un número telefónico válido"]
          : []),
        ...(!this.$v.phoneNumber.integer && this.phoneNumber
          ? ["Ingresa un número telefónico válido"]
          : []),
      ];
    },

    validateDiscountPercent() {
      this.$v.discountPercent.$touch();
      this.errors.discountPercent = [
        ...(this.$v.discountPercent.required
          ? []
          : ["El porcentaje de descuento es requerido"]),
        ...(this.$v.discountPercent.numeric
          ? []
          : ["Ingresa una cantidad válida"]),
        ...(this.discountPercent <= 100
          ? []
          : ["El porcentaje de descuento no puede ser mayor a 100%"]),
      ];
    },

    async submit() {
      this.validateForm();
      if (!this.isFormValid) return;

      this.loading = true;
      this.error = "";
      try {
        const requestBody = {
          name: this.name,
          lastName: this.lastName,
          identification: this.identification,
          discountPercent: this.discountPercent,
          ...(this.email && { email: this.email }),
          ...(this.phoneNumber && { phoneNumber: this.phoneNumber }),
          ...(this.adress && { adress: this.adress }),
        };

        const serverResponse = this.customer
          ? await this.putRequest(
              `/customers/${this.customer._id}`,
              requestBody
            )
          : await this.postRequest("/customers", requestBody);
        this.loading = false;

        this.$emit("customerSaved", serverResponse.customer);
      } catch (error) {
        this.loading = false;
        console.log(error);
        if (error.data) this.error = error.data.message;
        else {
          this.error = "Error inesperado, favor de contactar con soporte";
          console.error(error);
        }
      }
    },
  },
};
</script>
